import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import { Container, Row, Col, Jumbotron, Card } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faCoins, faUsers } from '@fortawesome/free-solid-svg-icons'
import ph from "../images/ph.jpg"

export const data = graphql`
  query {
    image: file(relativePath: { eq: "csl.jpg" }) {
      ...StandardImageFragment
    }
    steamImg: file(relativePath: { eq: "steam/6hotplate_after.jpg" }) {
      ...StandardImageFragment
    }
    carpetImg: file(relativePath: { eq: "carpet/csl3.jpg" }) {
      ...StandardImageFragment
    }
    tenancyImg: file(relativePath: { eq: "tenancy/csl2.jpg" }) {
      ...StandardImageFragment
    }
  }
`;

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" keywords={[`cleaning`, `commercial`, `north-east`, `carpet`, `steam cleaning`, `carpet cleaning`, `north yorkshire`, `steam cleaning services`, `end of tenancy`]} />
    <Jumbotron fluid className="bg-primary text-light">
      <div className="container">
        <div className="text-center text-xl-left">
          <h1 className="display-4 mb-2 mb-md-5">All your cleaning needs in one place</h1>
          <div className="lead">
            <p>We are your contract cleaning specialists and a launderette in one so we can provide everything you need at a great price.</p>
            <p>
              <a className="font-weight-bold text-light" href="tel:07732238973">Call</a> or <a className="font-weight-bold text-light" href="mailto:info@cslcleaningservices.co.uk">email</a> now for a free no obligation quote.
            </p>
          </div>
        </div>
      </div>
    </Jumbotron>
    <Container>
      <h2 className="my-4 font-weight-light">Your Contract Cleaning Specialists</h2>
      <Row className="mb-4">
        <Col sm={6} className="py-2 mb-3">
          <p>
            At CSL Cleaning Services we deliver a reliable, professional service that is tailored to your needs. We specialise in commercial cleaning and will undertake any job, big or small.
          </p>
          <p>
            We are a family run business and pride ourselves on going above and beyond when carrying out our services. We are based in Shildon, County Durham and cover our local area of the North East and North Yorkshire.</p>
          <p>
            Get in touch and see how we can help you.
          </p>
          <a className="btn btn-primary text-light btn-lg" href="/contact">Get In Touch</a>
        </Col>
        <Col sm={6} className="px-0">
          <Image fluid={data.image.childImageSharp.fluid} alt="Carpet cleaned by us with the clean side on the left and the dirty side on the right" />
        </Col>
      </Row>
    </Container>
    <div className="bg-brand-purple text-light py-5">
      <Container>
        <Row className="text-center">
          <Col sm={4} className="mb-3 mb-sm-0">
            <FontAwesomeIcon icon={faCoins} size="5x" />
            <p className="mt-4">We offer either fixed pricing or bespoke package deals</p>
          </Col>
          <Col sm={4}>
            <FontAwesomeIcon icon={faClock} size="5x" />
            <p className="mt-4">We can undertake work during the night hours or unsociable hours</p>
          </Col>
          <Col sm={4}>
            <FontAwesomeIcon icon={faUsers} size="5x" />
            <p className="mt-4">All our staff are CRB checked, professional, punctual and experienced</p>
          </Col>
        </Row>
      </Container>
    </div>
    <Container>
      <h2 className="my-4 font-weight-light">Our Services</h2>
      <Row>
        <Col sm={12} md={4} className="mb-3">
          <Card>
            <div className="card-img">
              <Image fluid={data.steamImg.childImageSharp.fluid} />
            </div>
            <Card.Body>
              <Card.Title>Kitchen Steam Cleaning</Card.Title>
              <Card.Text>We use industrial strength machinery and commercial kitchen cleaning products to get the best results possible.</Card.Text>
              <Card.Link href="/steam-cleaning">Find out more...</Card.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4} className="mb-3">
          <Card>
            <div className="card-img">
              <Image fluid={data.carpetImg.childImageSharp.fluid} />
            </div>
            <Card.Body>
              <Card.Title>Commercial Carpet Cleaning</Card.Title>
              <Card.Text>Keep your office carpets clean and in good condition to keep your entire working environment feeling fresh and vibrant.</Card.Text>
              <Card.Link href="/carpet-cleaning">Find out more...</Card.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4} className="mb-3">
          <Card>
            <div className="card-img">
              <Image fluid={data.tenancyImg.childImageSharp.fluid} />
            </div>
            <Card.Body>
              <Card.Title>End Of Tenancy</Card.Title>
              <Card.Text>We understand that for landlords, letting agents and tenants it is essential to leave a property in immaculate condition.</Card.Text>
              <Card.Link href="/end-of-tenancy">Find out more...</Card.Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage